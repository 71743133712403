import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"

const PropertyDetailPage = () => (
  <Layout>
    <SEO title="Page two" />
    <Container>
      <h1>Haaaaaaa</h1>
      <p>Welcome to page 2</p>
      <Link to="/">Go back to the homepage</Link>
    </Container>
  </Layout>
)

export default PropertyDetailPage
